import React from 'react';
import './FileList.css';
import { useNavigate, Link } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdfWorkerVersion = '3.11.174'; // PDF.jsのバージョン

const FileList = ({ files, showDeleteButton = false, onDelete }) => {
  const navigate = useNavigate();

  // ファイルプレビューのレンダリング
  const renderFilePreview = (file) => {
    const fileUrl = file.path;
  
    const previewStyle = {
      width: '100%',
      height: '300px',   // プレビューの高さ
      objectFit: 'cover',
      cursor: 'pointer', // クリック可能なカーソルに変更
    };
  
    // プレビュー全体をクリックしたときに詳細ページに遷移
    const handlePreviewClick = () => {
      navigate(`/MaterialDetail/${file.id}`);
    };
  
    if (file.file_type === 'PDF') {
      return (
        <div style={previewStyle} onClick={handlePreviewClick}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      );
    } 
    // 画像形式として`.ico`ファイルも認識するように変更
    else if (file.file_type === '画像形式' || file.filename.toLowerCase().endsWith('.ico')) {
      return <img src={file.path} alt={file.filename} style={previewStyle} onClick={handlePreviewClick} />;
    } 
    else if (file.file_type === 'Word' || file.file_type === 'Excel' || file.file_type === 'PowerPoint') {
      return (
        <div style={previewStyle} onClick={handlePreviewClick}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.path)}`}
            style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        </div>
      );
    } else {
      return (
        <div onClick={handlePreviewClick} style={previewStyle}>
          <p>このファイル形式のプレビューはサポートされていません。</p>
          <a href={file.path} target="_blank" rel="noopener noreferrer" download={file.filename}>
            ダウンロード
          </a>
        </div>
      );
    }
  };

  return (
    <div className="main-file-list">
      {files.length > 0 ? (
        files.map((file) => (
          <div key={file.id} className="main-file-item">
            <div className="main-file-info">
              {renderFilePreview(file)}
              <div className="sub-file-icon-info">
                <div className="sub-file-icon">
                  {file.user_icon ? (
                    <img src={file.user_icon} alt={`${file.user_name}のアイコン`} className="sub-user-icon" />
                  ) : (
                    <div className="default-icon">No Icon</div>
                  )}
                </div>
                <div className="sub-file-info">
                  <p>{file.filename}</p>
                  <p>投稿日：　{new Date(file.upload_date).toLocaleDateString()}</p>
                  <p>投稿者：　<Link to={`/userpage/${file.user_name}`}>{file.user_name}</Link></p>
                  <p>学校種別と教科：　{file.school_type} {file.subject}</p>
                  {/* 削除ボタンの表示を条件付きで行う */}
                  {showDeleteButton && (
                    <div className='delete-btn'>
                      <button onClick={() => onDelete(file.id, file.path)}>削除</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='no-material'>該当の教材はありません。</div>
      )}
    </div>
  );
};

export default FileList;
