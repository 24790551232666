import React, { useEffect, useState } from 'react';
import './MainContent.css';
import { Link } from 'react-router-dom';
import libraryImage from '../assets/main.png';
import UserIconSample from '../assets/iconsample.png';  
import axios from 'axios';
import FileList from './FileList';
import { Timeline } from 'react-twitter-widgets'; // Twitterのウィジェットコンポーネントをインポート

const MainContent = () => {
  const [files, setFiles] = useState([]);
  const [popularFiles, setPopularFiles] = useState([]); // 人気の資料用のステート
  const [currentPageIndex, setCurrentLatestPageIndex] = useState(0); // 最新の資料のページインデックス
  const [currentPopularPageIndex, setCurrentPopularPageIndex] = useState(0); // 人気の資料のページインデックス
  const [loadingFiles, setLoadingFiles] = useState(true); // 最新資料のロード状態
  const [loadingPopularFiles, setLoadingPopularFiles] = useState(true); // 人気資料のロード状態
  const filesPerPage = 9; // 1ページあたりの表示ファイル数

  // サーバーからファイル一覧を取得
  const fetchFiles = async () => {
    setLoadingFiles(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`);
      console.log('Fetched files:', response.data.files);
  
      const sortedFiles = response.data.files.sort((a, b) => b.id - a.id);
  
      // 各ファイルにアイコン情報を追加
      const filesWithIcons = await Promise.all(
        sortedFiles.map(async (file) => {
          try {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${file.user_name}`);
            const userIcon = userResponse.data.user.profile_image || UserIconSample; // アイコンまたはデフォルトアイコン
            return { ...file, user_icon: userIcon };
          } catch (error) {
            console.error(`Error fetching icon for user ${file.user_name}:`, error);
            return { ...file, user_icon: UserIconSample };
          }
        })
      );
  
      setFiles(filesWithIcons);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoadingFiles(false);
    }
  };

 // サーバーから人気のファイルを取得
const fetchPopularFiles = async () => {
  setLoadingPopularFiles(true);
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/popular-files`);
    console.log('Fetched popular files:', response.data.files);

    // 人気ファイルをフィルタリングして、お気に入り数が1以上のもののみ残す
    const filteredPopularFiles = response.data.files.filter(file => file.favoriteCount > 0);

    // フィルタリングされた人気ファイルをお気に入り数の降順でソート
    const sortedPopularFiles = filteredPopularFiles.sort((a, b) => b.favoriteCount - a.favoriteCount);

    // 各ファイルにアイコン情報を追加
    const filesWithIcons = await Promise.all(
      sortedPopularFiles.map(async (file) => {
        try {
          // 投稿者のアイコンを取得
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${file.user_name}`);
          const userIcon = userResponse.data.user.profile_image || UserIconSample; // アイコンまたはデフォルトアイコン
          return { ...file, user_icon: userIcon };
        } catch (error) {
          console.error(`Error fetching icon for user ${file.user_name}:`, error);
          return { ...file, user_icon: UserIconSample };
        }
      })
    );

    setPopularFiles(filesWithIcons);
  } catch (error) {
    console.error('Error fetching popular files:', error);
  } finally {
    setLoadingPopularFiles(false);
  }
};


  useEffect(() => {
    fetchFiles();
    fetchPopularFiles(); // 人気の資料を取得
  }, []);

  // 最新の資料の現在のページで表示するファイル
  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);

  // 人気の資料の現在のページで表示するファイル
  const currentPopularFiles = popularFiles.slice(currentPopularPageIndex * filesPerPage, (currentPopularPageIndex + 1) * filesPerPage);

  // 最新の資料の総ページ数を計算
  const totalLatestPages = Math.ceil(files.length / filesPerPage);

  // 人気の資料の総ページ数を計算
  const totalPopularPages = Math.ceil(popularFiles.length / filesPerPage);

  // 最新の資料の任意のページに移動する処理
  const goToLatestPage = (pageIndex) => {
    setCurrentLatestPageIndex(pageIndex);

    // 最新の資料セクションまでスクロール
    const latestSection = document.getElementById('latest-section');
    if (latestSection) {
      latestSection.scrollIntoView({ behavior: 'auto' });
    }
  };

  // 人気の資料の任意のページに移動する処理
  const goToPopularPage = (pageIndex) => {
    setCurrentPopularPageIndex(pageIndex);

    // 人気の資料セクションまでスクロール
    const popularSection = document.getElementById('popular-section');
    if (popularSection) {
      popularSection.scrollIntoView({ behavior: 'auto' });
    }
  };

  return (
    <main className="main-content">
      {/* currentPageIndexが0のときのみ表示 */}
      {currentPageIndex === 0 && (
        <div>
          <div className="image-container">
            <img src={libraryImage} alt="Library" className="library-image" />
            <div className="overlay"></div>
            <div className="welcome-text">ようこそedushareへ</div>
            <div className="description-text">
              授業者たちが、クリエイティブな<br />アイディアを共有し合う場所
            </div>
          </div>
          <div className="main-btn">
            <Link to="./MaterialSearch" className="MaterialSearch">アイディアを探索する</Link>
          </div>
        </div>
      )}
      
      {/* main-materialsにcurrentPageIndexが0かどうかでクラスを付与 */}
      <div className={`main-materials ${currentPageIndex !== 0 ? 'page-offset' : ''}`}>
        <p  id="latest-section" className="latest">最新の資料</p>
        
        {/* ローディング中はプレースホルダーを表示 */}
        {loadingFiles ? (
          <div>Loading 最新の資料...</div> // ローディングメッセージ
        ) : (
          <FileList files={currentFiles} />  // ファイルリストを表示
        )}

        {/* 最新資料のもっとみるの文字表示 */}
        {(currentPageIndex + 1) * filesPerPage < files.length && (
          <p onClick={() => goToLatestPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}

        {/* 最新資料のページングコントロール */}
        <div className="pagination-controls">
          {[...Array(totalLatestPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToLatestPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {/* Twitterタイムライン */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '40px 0' }}>
          <div style={{ transform: 'scale(0.8)', transformOrigin: 'center', width: '100%', maxWidth: '1000px' }}>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'mathroom314',
              }}
              options={{ height: 600, width: 1000 }}
              theme="light"
            />
          </div>
        </div>

        <p id="popular-section" className="recommend">人気の資料</p>
        
        {/* 人気資料の表示 */}
        {loadingPopularFiles ? (
          <div>Loading 人気の資料...</div> // ローディングメッセージ
        ) : (
          <FileList files={currentPopularFiles} />  // ファイルリストを表示
        )}

        {/* 人気の資料のもっとみるの文字表示 */}
        {(currentPopularPageIndex + 1) * filesPerPage < popularFiles.length && (
          <p onClick={() => goToPopularPage(currentPopularPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}

        {/* 人気の資料のページングコントロール */}
        <div className="pagination-controls">
          {[...Array(totalPopularPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPopularPage(index)}
              className={`page-number ${index === currentPopularPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>
      </div>
    </main>
  );
};

export default MainContent;
